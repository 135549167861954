import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AlertController } from '@ionic/angular';
import { Router } from '@angular/router';
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})
export class RestService {

  // apiUrl = 'http://localhost:8080';
  apiUrl = 'https://logbookservice.yuzudigital.com';

  constructor(
    private http: HttpClient,
    private alertCtrl: AlertController,
    private router: Router,
    private storage: Storage,
  ) {
    console.log('Hello RestProvider Provider');
  }

  postAPI(path, param: any) {
    return new Promise((resolve, reject) => {
      const header = new HttpHeaders();
      header.append('Content-type', 'json/data; charset=utf-8');

      this.http.post(this.apiUrl + path, param, { headers: header })
        .subscribe(res => {

          resolve(res);
        }, (err) => {
          reject(err);
        });
    });

  }

  putAPI(path, param: any) {
    return new Promise((resolve, reject) => {
      const header = new HttpHeaders();
      header.append('Content-type', 'json/data; charset=utf-8');

      this.http.put(this.apiUrl + path, param, { headers: header })
        .subscribe(res => {

          resolve(res);
        }, (err) => {
          reject(err);
        });
    });

  }

  deleteAPI(path) {
    return new Promise((resolve, reject) => {
      const header = new HttpHeaders();
      header.append('Content-type', 'json/data; charset=utf-8');

      this.http.delete(this.apiUrl + path, { headers: header })
        .subscribe(res => {

          resolve(res);
        }, (err) => {
          reject(err);
        });
    });

  }

  getAPI(path) {
    return new Promise((resolve, reject) => {
      const header = new HttpHeaders();
      header.append('Content-type', 'json/data; charset=utf-8');

      this.http.get(this.apiUrl + path)
        .subscribe(res => {

          resolve(res);
        }, (err) => {
          reject(err);
        });
    });

  }

  async alert(msg) {
    const alertPromp = await this.alertCtrl.create({
      header: 'Alert',
      message: msg,
      buttons: [
        {
          text: 'ตกลง',
          role: 'cancel',
          handler: () => {
            console.log('Cancel clicked');
          }
        }
      ]
    });
    await alertPromp.present();

  }

  async alertAndGo(msg, page) {
    const alertPromp = await this.alertCtrl.create({
      header: 'Alert',
      message: msg,
      buttons: [
        {
          text: 'ตกลง',
          role: 'cancel',
          handler: () => {
            console.log('Cancel clicked');
            this.router.navigate([page]);
          }
        }
      ]
    });
    await alertPromp.present();

  }

  getUserProfile() {
    return new Promise((resolve, reject) => {
      this.storage.get('logbookuser').then(user => {
        resolve(user);
      });
    });
  }

}
