import { Component, OnInit } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Storage } from '@ionic/storage';
import { Router } from '@angular/router';
import { Events } from './services/events.service';
import { OneSignal } from '@ionic-native/onesignal/ngx';
import { RestService } from './services/rest.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  public selectedIndex = 0;
  headerMenuItem = {
    background: 'assets/imgs/background/39.jpg',
    image: 'assets/imgs/logo/2.png',
    title: 'Ionic5 UI - Mikky Theme'
  };
  public appPages = [
    // {
    //   title: 'บันทึก',
    //   url: '/select-category',
    //   icon: 'add'
    // },
    // {
    //   title: 'รอการอนุมัติ',
    //   url: '/result-list',
    //   icon: 'list'
    // },
    // {
    //   title: 'อนุมัติแล้ว',
    //   url: '/approved-list',
    //   icon: 'checkmark'
    // },
    // {
    //   title: 'Pending Approve',
    //   url: '/pending-list',
    //   icon: 'list'
    // },
    // {
    //   title: 'ดูรายงาน',
    //   url: 'report',
    //   icon: 'document-text'
    // },
    // {
    //   title: 'เกี่ยวกับ logbook',
    //   url: 'howto',
    //   icon: 'information'
    // }
  ];

  public studentPage = [
    {
      title: 'บันทึก',
      url: '/select-category',
      icon: 'add'
    },
    {
      title: 'รอการอนุมัติ',
      url: '/result-list',
      icon: 'list'
    },
    {
      title: 'ดูรายงาน',
      url: 'report',
      icon: 'document-text'
    },
    {
      title: 'เกี่ยวกับ logbook',
      url: 'howto',
      icon: 'information'
    }
  ];

  public ajPage = [
    {
      title: 'Pending Approve',
      url: '/pending-list',
      icon: 'list'
    },
    {
      title: 'ดูรายงาน',
      url: 'teacher-report',
      icon: 'document-text'
    },
    {
      title: 'เกี่ยวกับ logbook',
      url: 'howto',
      icon: 'information'
    }
  ];

  public adminPage = [
    {
      title: 'อนุมัติสมาชิก',
      url: '/approve-user',
      icon: 'checkmark'
    },
    {
      title: 'สมาชิกทั้งหมด',
      url: '/all-user',
      icon: 'list'
    },
    {
      title: 'ดูรายงาน',
      url: 'admin-report',
      icon: 'document-text'
    },
  ];

  public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];
  userData: any;
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private storage: Storage,
    private router: Router,
    private events: Events,
    private oneSignal: OneSignal,
    private restApi: RestService,
  ) {
    this.initializeApp();

    events.subscribe('user:login', () => {
      this.storage.get('logbookuser').then(user => {
        if (user) {
          this.userData = user;
          this.updateOneSignalId(user);
          if (user.type === 'STUDENT') {
            this.appPages = this.studentPage;
            this.router.navigate(['/report']);
          } else if (user.type === 'TEACHER') {
            this.appPages = this.ajPage;
            this.router.navigate(['/pending-list']);
          } else if (user.type === 'ADMIN') {
            this.appPages = this.adminPage;
            this.router.navigate(['/approve-user']);
          }
        }
      });
    });

  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      this.setupPush();
      this.storage.get('logbookuser').then(user => {
        if (user) {
          this.userData = user;
          if (user.type === 'STUDENT') {
            this.appPages = this.studentPage;
            this.router.navigate(['/report']);
          } else if (user.type === 'TEACHER') {
            this.appPages = this.ajPage;
            this.router.navigate(['/pending-list']);
          } else if (user.type === 'ADMIN') {
            this.appPages = this.adminPage;
            this.router.navigate(['/approve-user']);
          }
          // this.router.navigate(['/report']);
        }
      });
    });
  }

  ngOnInit() {
  }

  onClickLogout() {
    this.storage.remove('logbookuser').then(logout => {
      this.router.navigate(['/landing']);
    });
  }

  setupPush() {

    // this.storage.set('deviceToken', 'abcd');
    // this.storage.set('hozsure_member_code', '8');

    // I recommend to put these into your environment.ts
    this.oneSignal.startInit('0e00d473-a2bb-4690-953b-6c89a515f81d', '980935735323');

    this.oneSignal.inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.None);

    // Notifcation was received in general
    this.oneSignal.handleNotificationReceived().subscribe(data => {
      const msg = data.payload.body;
      const title = data.payload.title;
      const additionalData = data.payload.additionalData;
      // this.showAlert(title, msg, additionalData.task);
      // this.goToPage(additionalData.goto, additionalData.page);
    });

    // Notification was really clicked/opened
    this.oneSignal.handleNotificationOpened().subscribe(data => {

      // this.router.navigate(['/tabs/checkinList'], { skipLocationChange: true });
      // Just a note that the data is a different place here!
      const additionalData = data.notification.payload.additionalData;
      // console.log(additionalData);

      // this.showAlert(additionalData.title, additionalData.detail, additionalData.goto);

      // this.goToPage(additionalData.goto, additionalData.page);
    });
    this.oneSignal.setSubscription(true);
    this.oneSignal.addPermissionObserver();
    this.oneSignal.endInit();
    this.oneSignal.getIds().then(user => {
      // alert(user.userId);
      console.log('onesignal userId: ' + user.userId);
      console.log('onesignal pushToken: ' + user.pushToken);

      this.storage.set('deviceToken', user.userId);

      this.storage.get('logbookuser').then(userStorage => {
        console.log(userStorage);
        // alert(userStorage.MEMBERIDCARD);
        if (userStorage) {
          const param = {
            onesignalId: user.userId
          };
          this.restApi.putAPI('/users/' + userStorage.id, param).then(res => {
            // this.presentComplete();
            console.log('updated onesignalID');
          });

        }
      });

    });
  }

  updateOneSignalId(user) {
    this.storage.get('deviceToken').then(onesignalId => {
      console.log(onesignalId);
      const param = {
        onesignalId
      };
      this.restApi.putAPI('/users/' + user.id, param).then(res => {
        // this.presentComplete();
        console.log('updated onesignalID');
      });
    });
  }
}
