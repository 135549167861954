import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'landing',
    pathMatch: 'full'
  },
  {
    path: 'landing',
    loadChildren: () => import('./landing/landing.module').then( m => m.LandingPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'add-result',
    loadChildren: () => import('./add-result/add-result.module').then( m => m.AddResultPageModule)
  },
  {
    path: 'result-list',
    loadChildren: () => import('./result-list/result-list.module').then( m => m.ResultListPageModule)
  },
  {
    path: 'select-category',
    loadChildren: () => import('./select-category/select-category.module').then( m => m.SelectCategoryPageModule)
  },
  {
    path: 'edit-result',
    loadChildren: () => import('./edit-result/edit-result.module').then( m => m.EditResultPageModule)
  },
  {
    path: 'view-result',
    loadChildren: () => import('./view-result/view-result.module').then( m => m.ViewResultPageModule)
  },
  {
    path: 'select-type',
    loadChildren: () => import('./select-type/select-type.module').then( m => m.SelectTypePageModule)
  },
  {
    path: 'subscribe',
    loadChildren: () => import('./subscribe/subscribe.module').then( m => m.SubscribePageModule)
  },
  {
    path: 'pending-list',
    loadChildren: () => import('./pending-list/pending-list.module').then( m => m.PendingListPageModule)
  },
  {
    path: 'unapprove-list',
    loadChildren: () => import('./unapprove-list/unapprove-list.module').then( m => m.UnapproveListPageModule)
  },
  {
    path: 'approved-list',
    loadChildren: () => import('./approved-list/approved-list.module').then( m => m.ApprovedListPageModule)
  },
  {
    path: 'result-view',
    loadChildren: () => import('./result-view/result-view.module').then( m => m.ResultViewPageModule)
  },
  {
    path: 'report',
    loadChildren: () => import('./report/report.module').then( m => m.ReportPageModule)
  },
  {
    path: 'howto',
    loadChildren: () => import('./howto/howto.module').then( m => m.HowtoPageModule)
  },
  {
    path: 'approve-user',
    loadChildren: () => import('./admin/approve-user/approve-user.module').then( m => m.ApproveUserPageModule)
  },
  {
    path: 'all-user',
    loadChildren: () => import('./admin/all-user/all-user.module').then( m => m.AllUserPageModule)
  },
  {
    path: 'teacher-report',
    loadChildren: () => import('./teacher-report/teacher-report.module').then( m => m.TeacherReportPageModule)
  },
  {
    path: 'change-password',
    loadChildren: () => import('./change-password/change-password.module').then( m => m.ChangePasswordPageModule)
  },
  {
    path: 'admin-report',
    loadChildren: () => import('./admin/admin-report/admin-report.module').then( m => m.AdminReportPageModule)
  },
  {
    path: 'admin-report-list',
    loadChildren: () => import('./admin/admin-report-list/admin-report-list.module').then( m => m.AdminReportListPageModule)
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./forgot-password/forgot-password.module').then( m => m.ForgotPasswordPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
